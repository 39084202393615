import React, {Component} from 'react';
import {IProduct} from '../../../../types/galleryTypes';
import {Dropdown, DropdownOptionProps, DropdownProps} from 'wix-ui-tpa/Dropdown';
import {ColorPicker} from 'wix-ui-tpa/ColorPicker';
import {RadioButtonChangeEvent, RadioButtonClickEvent} from 'wix-ui-core/src/components/radio-button/RadioButton';
import {IProductSelectionAvailabilityMap} from '@wix/wixstores-client-core/dist/es/src/productVariantCalculator/ProductVariantCalculator';
import s from './ProductOptions.scss';
import {ProductOptionType} from '@wix/wixstores-graphql-schema/dist/src/graphql-schema';
import {classes as dropdownOptionStyles} from './DropdownOption.st.css';
import {classes as colorOptionStyles} from './ColorOption.st.css';

export interface ProductOptionsChangeData {
  optionType: ProductOptionType;
}

export interface IProductOptionsProps {
  product: IProduct;
  selectionIds: number[];
  onSelectionIdsChange(
    optionSelections: IProductOptionsProps['selectionIds'],
    changeData: ProductOptionsChangeData
  ): void;
  variantsAvailability: IProductSelectionAvailabilityMap;
}

interface OptionRenderParams {
  option: IProduct['options'][number];
  availability: IProductSelectionAvailabilityMap[string];
}

export enum DataHook {
  ProductOption = 'product-option',
  DropdownOption = 'product-options-dropdown',
  ColorOption = 'product-options-color',
}

const PRODUCT_OPTIONS_MAX_VISIBLE_NUMBER = 2;

export class ProductOptions extends Component<IProductOptionsProps> {
  private readonly getAllVisibleDropdownOptions = () => {
    const colorOptsCount = this.getAllVisibleColorOptions().length;

    const slotsForDropdowns = PRODUCT_OPTIONS_MAX_VISIBLE_NUMBER - colorOptsCount;

    if (slotsForDropdowns <= 0) {
      return [];
    }

    return this.props.product.options
      .filter(({optionType}) => optionType === ProductOptionType.DROP_DOWN)
      .slice(0, slotsForDropdowns);
  };

  private readonly getAllVisibleColorOptions = () => {
    return this.props.product.options
      .filter(({optionType}) => optionType === ProductOptionType.COLOR)
      .slice(0, PRODUCT_OPTIONS_MAX_VISIBLE_NUMBER);
  };

  private readonly sealColorChangeHandler = (optionsSelections: OptionRenderParams['option']['selections']) => (
    event: RadioButtonChangeEvent | RadioButtonClickEvent
  ) => {
    const {selectionIds, onSelectionIdsChange} = this.props;

    const pickedSelectionId = optionsSelections.find(({value}) => value === event.value).id;

    const nextSelectionIds = [
      ...selectionIds.filter((selectionId) => !optionsSelections.find(({id}) => id === selectionId)),
      pickedSelectionId,
    ];

    onSelectionIdsChange(nextSelectionIds, {
      optionType: ProductOptionType.COLOR,
    });
  };

  private readonly sealDropdownChangeHandler = (optionsSelectionIds: number[]): DropdownProps['onChange'] => (
    selected
  ) => {
    const {selectionIds, onSelectionIdsChange} = this.props;

    const nextSelectionIds = [
      ...selectionIds.filter((selectionId) => !optionsSelectionIds.includes(selectionId)),
      parseInt(selected.id, 10),
    ];

    onSelectionIdsChange(nextSelectionIds, {
      optionType: ProductOptionType.DROP_DOWN,
    });
  };

  private readonly renderColorOption = ({option: {id, selections}, availability}: OptionRenderParams) => {
    const {selectionIds} = this.props;

    const colorItems = selections
      .filter((selection) => availability[selection.id].isVisible)
      .map((selection) => {
        const isDisabled = !availability[selection.id].isSelectable;
        return (
          <ColorPicker.Item
            key={selection.id}
            aria-label={selection.description}
            value={selection.value}
            disabled={isDisabled}
            isCrossedOut={isDisabled}
            tooltip={selection.description}
            checked={selectionIds.includes(selection.id)}
          />
        );
      });

    return (
      <div key={id} data-hook={DataHook.ProductOption}>
        <ColorPicker
          className={colorOptionStyles.colorOption}
          onChange={this.sealColorChangeHandler(selections)}
          data-hook={DataHook.ColorOption}>
          {colorItems}
        </ColorPicker>
      </div>
    );
  };

  private readonly renderDropdownOption = ({option: {id, title, selections}, availability}: OptionRenderParams) => {
    const options: DropdownOptionProps[] = selections
      .filter((selection) => availability[selection.id].isVisible)
      .map((selection) => ({
        id: selection.id.toString(),
        isSelectable: availability[selection.id].isSelectable,
        value: selection.description,
      }));

    return (
      <div data-hook={DataHook.ProductOption} key={id}>
        <Dropdown
          className={dropdownOptionStyles.dropdownOption}
          data-hook={DataHook.DropdownOption}
          placeholder={title}
          options={options}
          onChange={this.sealDropdownChangeHandler(selections.map((selection) => selection.id))}
        />
      </div>
    );
  };

  private readonly renderAllColorOptions = () => {
    const colorOptions = this.getAllVisibleColorOptions();

    const {variantsAvailability} = this.props;

    return colorOptions.map((option) => (
      <div key={option.id} className={s.color}>
        {this.renderColorOption({option, availability: variantsAvailability[option.id]})}
      </div>
    ));
  };

  private readonly renderAllDropdownOptions = () => {
    const dropDownOptions = this.getAllVisibleDropdownOptions();

    const {variantsAvailability} = this.props;

    return dropDownOptions.map((option) => (
      <div key={option.id} className={s.dropdown}>
        {this.renderDropdownOption({option, availability: variantsAvailability[option.id]})}
      </div>
    ));
  };

  public render() {
    return (
      <>
        {this.renderAllColorOptions()}
        {this.renderAllDropdownOptions()}
      </>
    );
  }
}
